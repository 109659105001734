// OSCAR PAGE STRUCTURE
// --------------------

body {
  background-color: $gray-200;
}

// The page container
.page .page_inner {
  background: $white;
  border-radius: 0 0 6px 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
}

// Some padding for the inner container
// Also useful for mobile
.page_inner {
  padding: $grid-gutter-width;
  @include clearfix();
}
