// Page / sub headers consistant
.page-header, .sub-header {
  border-bottom: 1px solid $gray-200;
  margin-bottom: 0.625rem;
}

// Sub header a little lighter
.sub-header {
  h2, h3, h4 {
    font-size: $font-size-base * 1.6;
    color: $gray-800;
  }
}
