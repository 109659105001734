// Additional styles for alert content. Used by both public side and dashboard

.alert {
  @include border-radius(0);

  margin-top: 20px;

  .close {
    z-index: 5;
  }

  .alertinner {
    position: relative;

    &.wicon {
      padding-left: 0.5em;
      margin-left: 0.5em;
    }

    [class*="fa-"] {
      position: absolute;
      top: 0.3em;
      right: 100%;
    }

    p {
      margin: 10px 0 0 0;

      &:first-child {
        margin: 0;
      }
    }
  }
}

.alert-dismissible {
  .close {
    padding: 0.5rem 0.75rem;
  }
}

.alert-message.block-message.file_upload p {
  margin-right: 10px;
}
